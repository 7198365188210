.root {
  font-size: var(--font-size-2);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-000);
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
  white-space: nowrap;
  border-radius: 2px;
  display: inline-block;
}
.colorPrimary {
  background-color: #2C7B8C;
}
.colorGrey {
  background-color: #a5a5a5;
}
.colorError {
  background-color: var(--palette-error-500);
}
.colorDarkest {
  background-color: #11435D;
}
.colorStreamBlue {
  background-color: var(--palette-primary-500);
}
.variantPill {
  border-radius: 20px;
  padding: 0px 10px;
  padding: 2px 10px;
  background-color: inherit;
}
.variantPill.colorGrey {
    border: 1px solid var(--palette-grey-500);
    color: var(--palette-grey-500);
  }
.variantPill.colorPrimary {
    border: 1px solid #2C7B8C;
    color: #2C7B8C;
  }
.variantPill.colorError {
    border: 1px solid var(--palette-error-500);
    color: var(--palette-error-500);
  }
.variantPill.colorDarkest {
    border: 1px solid #11435D;
    color: #11435D;
  }
.variantPill.colorStreamBlue {
    border: 1px solid var(--palette-primary-500);
    color: var(--palette-primary-500);
  }
.uppercase {
  text-transform: uppercase;
}
