.base {
  border-radius: var(--round-corners);
  outline: none;
}

.upperCase {
  text-transform: uppercase;
}

.fontFamilyPrimary {
  font-family: 'Lato', sans-serif;
}

.fontFamilySecondary{
  font-family: 'Lato', sans-serif;
}

.fontWeightPrimaryBold {
  font-weight: var(--font-weight-primary-bold);
}

.fontWeightPrimarySemiBold {
  font-weight: var(--font-weight-primary-semi-bold);
}

.fontWeightPrimaryRegular {
  font-weight: var(--font-weight-primary-regular);
}

.fontWeightSecondaryBold {
  font-weight: var(--font-weight-secondary-bold);
}

.fontWeightSecondarySemiBold {
  font-weight: var(--font-weight-secondary-bold);
}

.fontWeightSecondaryRegular {
  font-weight: var(--font-weight-secondary-regular);
}

.fontSizeExtraSmall {
  font-size: var(--font-size-1);
  line-height: 1.33;
}

.fontSizeSmall {
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.fontSizeMedium {
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.fontSizeLarge {
  font-size: var(--font-size-3);
  line-height: 1;
}

.textAlignLeft {
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.paddingSizeExtraSmall {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.paddingSizeSmall {
  /* padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2); */
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 4px !important;
}

.paddingSizeMedium {
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
}

.paddingSizeLarge {
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
}

.filled {
  border-style: solid;
  border-width: 1px;
}

.filled.colorPrimary {
    color: var(--palette-text-000);
  }

.filled.colorPrimary:not(.disabled),
    .filled.colorPrimary:not(:disabled) {
      background-color: var(--palette-primary-500);
      border-color: var(--palette-primary-500);
    }

.filled.colorPrimary:hover, .filled.colorPrimary.mouseHover {
      background-color: var(--palette-primary-600);
      border-color: var(--palette-primary-600);
    }

.filled.colorPrimary:active, .filled.colorPrimary.active {
      background-color: var(--palette-primary-700);
      border-color: var(--palette-primary-700);
    }

.filled.colorPrimary.disabled {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.filled.colorSecondary {
    color: var(--palette-text-000);
    letter-spacing: .6px;
  }

.filled.colorSecondary:not(.disabled),
    .filled.colorSecondary:not(:disabled) {
      background-color: var(--palette-grey-500);
      border-color: var(--palette-grey-500);
    }

.filled.colorSecondary:hover, .filled.colorSecondary.mouseHover {
      background-color: var(--palette-grey-600);
      border-color: var(--palette-grey-600);
    }

.filled.colorSecondary:active, .filled.colorSecondary.active {
      background-color: var(--palette-grey-700);
      border-color: var(--palette-grey-700);
    }

.filled.colorSecondary.disabled {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.filled.colorError {
    color: var(--palette-text-000);
  }

.filled.colorError:not(.disabled),
    .filled.colorError:not(:disabled) {
      background-color: var(--palette-error-500);
      border-color: var(--palette-error-500);
    }

.filled.colorError:hover, .filled.colorError.mouseHover {
      background-color: var(--palette-error-600);
      border-color: var(--palette-error-600);
    }

.filled.colorError:active, .filled.colorError.active {
      background-color: var(--palette-error-700);
      border-color: var(--palette-error-700);
    }

.filled.colorError.disabled {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.filled.colorSuccess {
    color: var(--palette-text-000);
  }

.filled.colorSuccess:not(.disabled),
    .filled.colorSuccess:not(:disabled) {
      background-color: var(--palette-success-500);
      border-color: var(--palette-success-500);
    }

.filled.colorSuccess:hover, .filled.colorSuccess.mouseHover {
      background-color: var(--palette-success-600);
      border-color: var(--palette-success-600);
    }

.filled.colorSuccess:active, .filled.colorSuccess.active {
      background-color: var(--palette-success-700);
      border-color: var(--palette-success-700);
    }

.filled.colorSuccess.disabled {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.filled.colorBlack {
    color: #FFFFFF;
    border-width: 2px;
    border-radius: 6px;
  }

.filled.colorBlack:not(.disabled),
    .filled.colorBlack:not(:disabled) {
      background-color: #1ab394;
      border-color: #1ab394;
      color: #FFFFFF;
    }

.filled.colorBlack:hover, .filled.colorBlack.mouseHover {
      background-color: #FFFFFF;
      border-color: #1ab394;
      color: #1ab394;
    }

.filled.colorBlack:active, .filled.colorBlack.active {
      background-color: #FFFFFF;
      border-color: #1ab394;
      color: #1ab394;  
    }

.filled.colorBlack.disabled {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.outlined.colorPrimary {
    border-style: solid;
    border-width: 1px;
  }

.outlined.colorPrimary:not(.disabled),
    .outlined.colorPrimary:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-primary-500);
      color: var(--palette-primary-500);
    }

.outlined.colorPrimary:hover, .outlined.colorPrimary.mouseHover {
      background-color: var(--palette-primary-500);
      border-color: var(--palette-primary-500);
      color: var(--palette-text-000);
    }

.outlined.colorPrimary:active, .outlined.colorPrimary.active {
      background-color: var(--palette-primary-600);
      border-color: var(--palette-primary-600);
      color: var(--palette-text-000);
    }

.outlined.colorPrimary.disabled {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.outlined.colorSecondary {
    border-style: solid;
    border-width: 1px;
    letter-spacing: .6px;
  }

.outlined.colorSecondary:not(.disabled),
    .outlined.colorSecondary:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-grey-500);
      color: var(--palette-grey-500);
    }

.outlined.colorSecondary:hover, .outlined.colorSecondary.mouseHover {
      background-color: var(--palette-grey-500);
      border-color: var(--palette-grey-500);
      color: var(--palette-text-000);
    }

.outlined.colorSecondary:active, .outlined.colorSecondary.active {
      background-color: var(--palette-grey-600);
      border-color: var(--palette-grey-600);
      color: var(--palette-text-000);
    }

.outlined.colorSecondary.disabled {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.outlined.colorError {
    border-style: solid;
    border-width: 1px;
  }

.outlined.colorError:not(.disabled),
    .outlined.colorError:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-error-500);
      color: var(--palette-error-500);
    }

.outlined.colorError:hover, .outlined.colorError.mouseHover {
      background-color: var(--palette-error-500);
      border-color: var(--palette-error-500);
      color: var(--palette-text-000);
    }

.outlined.colorError:active, .outlined.colorError.active {
      background-color: var(--palette-error-600);
      border-color: var(--palette-error-600);
      color: var(--palette-text-000);
    }

.outlined.colorError.disabled {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.outlined.colorSuccess {
    border-style: solid;
    border-width: 1px;
  }

.outlined.colorSuccess:not(.disabled),
    .outlined.colorSuccess:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-success-500);
      color: var(--palette-success-500);
    }

.outlined.colorSuccess:hover, .outlined.colorSuccess.mouseHover {
      background-color: var(--palette-success-500);
      border-color: var(--palette-success-500);
      color: var(--palette-text-000);
    }

.outlined.colorSuccess:active, .outlined.colorSuccess.active {
      background-color: var(--palette-success-600);
      border-color: var(--palette-success-600);
      color: var(--palette-text-000);
    }

.outlined.colorSuccess.disabled {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.outlined.colorBlack {
    border-style: solid;
    border-width: 2px;
  }

.outlined.colorBlack:not(.disabled),
    .outlined.colorBlack:not(:disabled) {
      background-color: #FFFFFF;
      border-color: #1ab394;
      color: #1ab394;
    }

.outlined.colorBlack:hover, .outlined.colorBlack.mouseHover {
      background-color: #1ab394;
      border-color: #1ab394;
      color: #FFFFFF;
    }

.outlined.colorBlack:active, .outlined.colorBlack.active {
      background-color: #1ab394;
      border-color: #1ab394;
      color: #FFFFFF;
    }

.outlined.colorBlack.disabled {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.flat {
  background-color: transparent;
}

.flat.colorPrimary:not(.disabled),
    .flat.colorPrimary:not(:disabled) {
      color: var(--palette-primary-500);
    }

.flat.colorPrimary:hover, .flat.colorPrimary.mouseHover {
      color: var(--palette-primary-600);
    }

.flat.colorPrimary:active, .flat.colorPrimary.active {
      color: var(--palette-primary-700);
    }

.flat.colorPrimary.disabled {
      color: var(--palette-grey-400);
    }

.flat.colorSecondary {
    letter-spacing: .6px;
  }

.flat.colorSecondary:not(.disabled),
    .flat.colorSecondary:not(:disabled) {
      color: var(--palette-grey-500);
    }

.flat.colorSecondary:hover, .flat.colorSecondary.mouseHover {
      color: var(--palette-grey-600);
    }

.flat.colorSecondary:active, .flat.colorSecondary.active {
      color: var(--palette-grey-700);
    }

.flat.colorSecondary.disabled {
      color: var(--palette-grey-400);
    }

.flat.colorError:not(.disabled),
    .flat.colorError:not(:disabled) {
      color: var(--palette-error-500);
    }

.flat.colorError:hover, .flat.colorError.mouseHover {
      color: var(--palette-error-600);
    }

.flat.colorError:active, .flat.colorError.active {
      color: var(--palette-error-700);
    }

.flat.colorError.disabled {
      color: var(--palette-grey-400);
    }

.flat.colorSuccess:not(.disabled),
    .flat.colorSuccess:not(:disabled) {
      color: var(--palette-success-500);
    }

.flat.colorSuccess:hover, .flat.colorSuccess.mouseHover {
      color: var(--palette-success-600);
    }

.flat.colorSuccess:active, .flat.colorSuccess.active {
      color: var(--palette-success-700);
    }

.flat.colorSuccess.disabled {
      color: var(--palette-grey-400);
    }

.flat.colorBlack:not(.disabled),
    .flat.colorBlack:not(:disabled) {
      color: var(--palette-grey-900);
    }

.flat.colorBlack:hover, .flat.colorBlack.mouseHover {
      color: var(--palette-grey-700);
    }

.flat.colorBlack:active, .flat.colorBlack.active {
      color: var(--palette-grey-800);
    }

.flat.colorBlack.disabled {
      color: var(--palette-grey-400);
    }

.underline {
  text-decoration: underline;
}

.fullWidth {
  width: 100%;
}

@media only screen and(max-width: 700px){
  .filled
  &.colorBlack{
    padding: 10px;
  }
}
