$counterBadgeForegroundColor: var(--palette-text-000);
$counterBadgeBackgroundDefaultColor: var(--palette-grey-500);
$counterBadgeBackgroundEmphasisColor: var(--palette-grey-500);
$counterBadgeBackgroundAlertColor: var(--palette-grey-500);

.root {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  letter-spacing: calc(0.2em / 14);
  border-radius: var(--round-corners);
  padding: 1px 3px 1px 3px;
  color: inherit;
}

.sizeSmall {
  font-size: var(--font-size-1);
  padding: 2px 3px;
  line-height: 1.33;
}

.sizeLarge {
  font-size: var(--font-size-4);
  padding: 2px 3px;
  line-height: 1.33;
}

.text {
  color: $counterBadgeForegroundColor;
}

.white {
  color: $counterBadgeForegroundColor;
}

.black {
  color: var(--palette-grey-700);
  /* border: var(--palette-grey-400) 1px solid; */
  padding: 0px 5px;
  font-size: 12px;
  background-color: #fff !important;
}

.white_font {
  color: $counterBadgeForegroundColor;
}

.black_font {
  color: var(--palette-grey-700);
}


.colorInherit {
  background-color: currentColor;
}

.colorPrimary {
  background-color: $colors-teal-700;
}

.colorGrey {
  background-color: var(--palette-grey-200);
  box-shadow: inset 0px 0px 0px 1px var(--palette-grey-600);
  & .text {
    color: var(--palette-grey-600);
  }
}

.colorDark {
  background-color: var(--palette-grey-600);
}

.colorError {
  background-color: var(--palette-error-700);
}

.colorEmphasis {
  background-color: $counterBadgeBackgroundEmphasisColor;
}

.colorDefault {
  background-color: $counterBadgeBackgroundDefaultColor;
}

.colorAlert {
  background-color: $counterBadgeBackgroundAlertColor;
}

@media (min-width: $breakpoints-sm) {
  .black {
    font-size: 14px;
  }
}