.readOnly {
  cursor: not-allowed !important;
  color: var(--palette-text-500) !important;
  opacity: 1 !important;
}

.button {
  padding-left: 0;
}

.reacted {
  font-weight: var(--font-weight-primary-bold);
}

.icon {
  margin-right: var(--spacing-1);
}

.totalReactions {
  /* margin-left: var(--spacing-1); */
  /* margin-left: -5px; */
}

.thumbup {
  background-color: #3193f8;
  color: white;
  border-radius: 50%;
  /* position: absolute; */
  left: 0px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 3px;
  font-size: 10px;
  width: 11px;
  height: 11px;
  margin-right: 5px;
}
.dot {
  color: rgb(142, 142, 142);
  margin-right: 8px;
  margin-left: 8px;
}

.likeContainer {
  justify-content: space-between;
  /* width: 80px; */
  align-items: center;
  border-right: 1px solid #d9d9d9;
  padding-right: 12px;
}

.hideRightBorder {
  border: none;
}