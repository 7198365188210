.root {
}

.input {
  cursor: pointer;
  position: absolute; /* take it out of document flow */
  opacity: 0; /* hide it */
}

.label {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #676a6c;
  font-size: 14px;
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.3;
  align-items: center;
  margin-bottom: 5px;
}

.labelChecked {
  font-weight: var(--font-weight-primary-semi-bold);
}

.labelLight {
  color: var(--palette-text-000);
}

/* Box. */
.input + .label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: var(--palette-background-input);
  border: 1px solid var(--palette-text-500);
  border-radius: 50%;
  box-sizing: border-box;
  flex-shrink: 0;
}

/* Box focus */
.label.focus:before {
  @mixin outline;
}

/* Box checked */
.input:checked + .label:before {
  border: 1px solid var(--palette-text-500);
  background: var(--palette-background-input);
}

/* Disabled state label. */
.input:disabled + .label {
  cursor: auto;
  opacity: 0.6;
}

/* Disabled box. */
.input:disabled + .label:before {
  box-shadow: none;
  border: 1px solid var(--palette-text-input-disabled);
  background: var(--palette-background-input-disabled);
}

/* Checkmark. Could be replaced with an image */
.input:checked + .label:after {
  content: "";
  background: var(--palette-text-500);
  border-radius: 50%;
  position: absolute;
  left: 3px;
  width: 8px;
  height: 8px;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.input:checked:disabled + .label:after {
  background: var(--palette-background-input-disabled);
}
