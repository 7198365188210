.icons {
  color: var(--palette-warning-900);
  color: #1ab394;
}
  .icons:hover, .icons:focus {
    outline: transparent;
  }

/* color: #676a6c !important; */

.black:hover {
    outline: transparent;
  }

.yellow {
  color: var(--palette-warning-900);
  font-size: 30px;
  width: 35px;
}

.teal {
  color: #1ab394;
}

.interactive:hover {
  cursor: pointer;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
