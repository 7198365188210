.root {
  display: flex;
  align-items: flex-end;
  padding: 0;
  margin: 0;
}
.default {
  border-bottom: 1px solid var(--palette-grey-100);
}
.primary {
  border-bottom: 1px solid var(--palette-grey-300);
}
.secondary {
  border-bottom: 1px solid var(--palette-divider);
}
.streamSecondary {
  border-bottom: 1px solid var(--palette-divider);
}
.black_comments_tab {
  /* border-bottom: 1px solid var(--palette-grey-000); */
}
.streamPrimary {
  border-bottom: 1px solid var(--palette-grey-300);
}
