.root {
}
.popover {
  background: var(--palette-background-popover);
  border: 1px solid var(--palette-grey-400);
  box-sizing: border-box;
  box-shadow: var(--shadow-popover);
  border-radius: var(--round-corners);
  z-index: 300;
  margin: 2px;
}
.colorDark {
  background: var(--palette-background-tooltip);
  border-color: var(--palette-background-tooltip);
}
.top {
  margin: calc(0.5 * var(--mini-unit)) 0;
}
.left {
  margin: 0 calc(0.5 * var(--mini-unit));
}
.right {
  margin: 0 calc(0.5 * var(--mini-unit));
}
.bottom {
  margin: calc(0.5 * var(--mini-unit)) 0;
  /* left: 12px !important; */
}
